.wrapper {
    display: grid;
    justify-content: center;
    gap: 40px;
    margin-top: 20px;
    color: #122443;
}

.grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 50px);
    gap: 20px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        font-family: inherit;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        color: inherit;
        border: 1px solid rgb(18, 36, 67);
        border-radius: 50%;

        &.removeBtn {
            border: 0;

            svg {
                width: 40px;
                height: 40px;
                stroke-width: 1;
                stroke: rgba(18, 36, 67, 0.6);
            }
        }
    }
}

.pinDots {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.pinDotsItem {
    width: 12px;
    height: 12px;
    border: 2px solid rgba(18, 36, 67, 0.6);
    border-radius: 50%;
}

.pinDotsItemSuccess {
    background-color: rgba(0, 144, 0, 0.8);
    border: 0;
}

.withLoginButton {
    padding: 3px 5px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    color: inherit;
    letter-spacing: 0.5px;
}
