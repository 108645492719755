.solution-page {
    background-image: $gradient-red;
}

.solution-results {
    flex-grow: 1;
    background: #f4f7f9;
    border-radius: 20px 20px 0px 0px;
    margin: -1rem -1.5rem 0;

    &__wrapper {
        padding: 1.5rem 1.5rem 2.5rem;
    }

    &__title {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        color: rgba(18, 36, 67, 0.4);
        margin-bottom: 0.5em;
    }

    &__name {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0.5px;
        color: rgba(18, 36, 67, 1);
        margin-bottom: 0.5em;
    }

    &__desc {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2625px;
        color: rgba(18, 36, 67, 0.6);
        margin-bottom: 1.5rem;
    }

    &__items,
    &__times {
        padding-top: 1.5rem;
        border-top: 1px solid rgba(18, 36, 67, 0.1);
    }

    &__times {
        padding-bottom: 1.5rem;
    }

    &__doc-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 1rem;
        color: #fff;
        font-size: 14px;
        line-height: 22px;
        border-radius: 8px;
        margin-bottom: 1.5rem;
        letter-spacing: 0.2625px;
        box-shadow: 0 2px 10px -3px #ffa520;
        background: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);

        &::before {
            content: "";
            width: 22px;
            height: 22px;
            margin-right: 12px;
            background: url(../img/ic-info.svg) no-repeat center / contain;
        }

        &::after {
            content: "";
            width: 22px;
            height: 22px;
            margin-left: auto;
            border-radius: 4px;
            background-size: auto 8px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: rgba(255, 255, 255, 0.24);
            background-image: url(../img/ic-arrow-next.svg);
        }
    }

    &__report-btn {
        box-shadow: 0 2px 10px -3px #8f94fb;
        background: linear-gradient(90deg, #4e54c8 0%, #8f94fb 100%);
    }

    &__result-btn {
        background: linear-gradient(270deg, #a6d774 0%, #69b02c 100%);
        box-shadow: 0 2px 10px -3px #a6d774;
        margin-bottom: 10px;
    }

    &__diagram-btn {
        background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);
        box-shadow: 0 2px 10px -3px #00c6ff;
    }

    &__recommendation-btn {
        background: linear-gradient(270deg, #c8a1e0 0%, #674188 100%);
        box-shadow: 0 2px 10px -3px #c8a1e0;
    }

    &__note-btn {
        background: linear-gradient(270deg, #d988b9 0%, #713abe 100%);
        box-shadow: 0 2px 10px -3px #d988b9;
    }

    &__scales {
        border-top: 1px solid rgba(18, 36, 67, 0.1);
        margin-top: 24px;
    }
    &__scale {
        label {
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.23999999463558197px;
            text-align: left;
            opacity: 0.4;
            margin-top: 17px;
            display: block;
        }
        h5 {
            font-size: 12px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.23999999463558197px;
            text-align: left;
            opacity: 0.4;
            margin-top: 0;
        }
        h3 {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0.4000000059604645px;
            text-align: left;
            margin-top: 8px;
            margin-bottom: 8px;
            display: flex;
            align-items: center;

            img {
                margin-left: 12px;
                cursor: pointer;
            }
        }
    }
}

.solution-result {
    padding: 1rem;
    position: relative;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 2px 10px -3px rgba(38, 51, 73, 0.1);
    cursor: pointer;

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: rgba(18, 36, 67, 1);
        margin-bottom: 11px;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(18, 36, 67, 1);

        & + & {
            margin-top: 12px;
        }
    }

    &__row-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.233333px;
        color: rgba(18, 36, 67, 0.4);
    }

    &__mark {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.233333px;

        padding: 2px 8px;
        border-radius: 6px;

        &_green {
            background: #52c22b;
        }

        &_red {
            background: #ff5a52;
        }
    }

    & + & {
        margin-top: 12px;
    }
}
