.landing-contacts__contact_tel label {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.03em;
    text-align: left;
    display: block;
}

.no-institution-modal {
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-institution-modal__inner {
    width: 100%;
    max-width: 300px;
    background-color: white;
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-institution-modal__text {
    margin-bottom: 10px;
    text-align: center;

    color: #122443;
    font-weight: 500;
    font-size: 16px;
}

.input-field {
    width: 100%;
    font-size: 16px;
}

.no-institution-modal__btn {
    width: 100%;
    background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);
    color: white;
    font-weight: 500;
    font-size: 16px;
    border-radius: 16px;
    padding: 12px 0;
    border: none;
    cursor: pointer;
}
