.title {
    font-size: 18px;
    font-weight: 500;
    color: #122443;
    text-align: center;
}

.classList {
    margin-top: 20px;
}

.classItem {
    width: 100%;
    font: inherit;
    text-align: left;
    transition: border-color 0.3s, background-color 0.3s;
}

.active {
    border-color: rgba(0, 130, 9, 0.7);
    background-color: rgba(173, 255, 77, 0.1);
}

.conditionItem {
    padding: 20px;
}

.buttonWrapper {
    flex-grow: 1;
    align-content: flex-end;
    margin-top: 1.5rem;

    & button span {
        line-height: 22px;
    }
}
