.noCallBtn {
    margin-top: 8px;

    button {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: 0.32px;
        color: #122443;
        padding: 3px 10px;
        background-color: transparent;
    }
}
