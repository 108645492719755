.problem-page {
    &__h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.30000001192092896px;
        text-align: left;
        margin-bottom: 0;
    }

    &__h4 {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.4000000059604645px;
        text-align: left;
        margin-top: 30px;
        margin-bottom: 15px;
    }
    &__not-found {
        margin-top: 20px;
        text-align: center;
    }
    &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.26249998807907104px;
        text-align: left;
    }
    &__card {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        padding: 16px;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        border-radius: 16px;
        background-color: white;
        margin-bottom: 17px;
        cursor: pointer;

        & > div:first-child {
            flex: 0 1 auto;
        }

        & > button {
            flex: 0 0 45px;
        }

        &-favorite-btn {
            position: absolute;
            top: 16px;
            right: 26px;
            font-size: 20px;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }
    &__type {
        width: 42px;
        height: 22px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.20000000298023224px;
        text-align: left;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;

        &_Test {
            background: linear-gradient(270deg, #eb3349 0%, #f45c43 100%);
        }
    }
    &__goTo {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(244, 247, 249, 1);
        border-radius: 4px;

        img {
            filter: invert(1);
            height: 8px;
        }
    }
}
