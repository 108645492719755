.root {
    position: fixed;
    top: -120px;
    right: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: top 0.35s;

    @media (min-width: 1024px) {
        right: 30px;
        left: auto;
    }
}

.active {
    top: 20px;

    @media (min-width: 1024px) {
        top: 30px;
    }
}

.inner {
    position: relative;
    background-color: #e7effe;
    color: #2330ba;
    min-height: 0;
    padding: 20px;
    border-radius: 16px;
    border: 1px solid rgba(35, 48, 186, 0.4);
    box-shadow: 0 2px 14px 2px rgba(38, 51, 73, 0.15);
    width: 300px;
    text-align: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.26249998807907104px;
    opacity: 0.8;
}

.text {
    margin-top: 12px;
    font-size: 16px;
    line-height: 1.3;
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    z-index: 1;

    svg {
        width: 20px;
        height: 20px;
        color: #2330ba;
        opacity: 0.8;
        pointer-events: none;
    }
}
