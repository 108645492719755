.fileBtn {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.26px;
    padding: 16px;
    color: #122443;
    font-weight: 500;
    line-height: 22px;
    font-family: inherit;
    text-align: left;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    span {
        flex-grow: 1;
        padding: 2px 12px 0 12px;
    }

    img {
        width: 22px;
        height: 22px;
    }
}

.hr {
    height: 1px;
    background-color: rgba(18, 36, 67, 0.1);
    margin: 24px 0;
}
