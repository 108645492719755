.management-reporting {
    &__title {
        color: #122443;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0.5px;
        margin-bottom: 0;
    }

    &__dates {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 10px;
        column-gap: 20px;
        margin-top: 40px;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    &__date-block {
        padding: 16px;
        color: #122443;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        & .rdt input {
            text-align: center;
        }
    }

    &__description {
        margin-top: 40px;
        padding: 16px;
        color: rgba(18, 36, 67, 0.8);
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.23px;
        text-align: center;
    }
}

.rdt input {
    width: auto;
    font: inherit;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    cursor: pointer;
}
