.workWithMethods-page {
    &__description {
        margin-bottom: 10px;
        padding: 16px;
        color: #122443;
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
    }

    &__list a:not(:first-child),
    &__list button:not(:first-child) {
        margin-top: 20px;
    }
}

.work-with-methods {
    &__ages {
        &-block {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
        }

        &-checkbox {
            display: flex;

            input[type="checkbox"] {
                width: 0;
                height: 0;
                opacity: 0;
            }

            input[type="checkbox"] + label {
                cursor: pointer;
                padding: 8px 40px 8px 8px;
                position: relative;

                color: #122443;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: 0.3px;
                border-radius: 8px;
                background-color: #fff;

                &::after {
                    top: 50%;
                    right: 8px;
                    position: absolute;
                    content: "";
                    width: 22px;
                    height: 22px;
                    border-radius: 4px;
                    transform: translateY(-50%);
                    background: rgba(18, 36, 67, 0.06);
                }
            }

            input[type="checkbox"]:checked + label {
                color: #fff;
                background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

                &::after {
                    background: #fff;
                    box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
                }

                &::before {
                    top: 50%;
                    right: 8px;
                    z-index: 1;
                    position: absolute;
                    content: "";
                    width: 22px;
                    height: 22px;
                    transform: translateY(-50%);
                    background: url(../img/ic-check.svg) no-repeat center;
                }
            }
        }

        &-next-btn {
            padding: 1rem;
            display: block;
            cursor: pointer;
            position: relative;
            border-radius: 16px;
            margin-top: auto;
            background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);
            font-family: inherit;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.4px;
            color: #fff;
            text-align: left;

            p:last-child {
                font-size: 14px;
                letter-spacing: 0.2625px;
                color: rgba(255, 255, 255, 0.6);
            }

            &::after {
                top: 1rem;
                right: 1rem;
                content: "";
                width: 44px;
                height: 44px;
                border-radius: 50%;
                position: absolute;
                background-size: auto 12px;
                background-position: center;
                background-repeat: no-repeat;
                background-color: rgba(255, 255, 255, 0.2);
                background-image: url(../img/ic-arrow-next.svg);
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }
    }
}
