.sureAlert{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &__inner{
    min-width: 277px;
    max-width: 350px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    @media (max-width: 359px) {
      max-width: 300px;
    }

    h3{
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.3199999928474426px;
      text-align: center;
      text-transform: uppercase;
      color: #122443;
      margin-top: 36px;
    }
    p{
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.3199999928474426px;
      text-align: center;
      opacity: 0.6;
      margin-bottom: 30px;
      padding: 0 20px;
      color: #122443;
    }
    .send{
      width: 225px;
      height: 53px;
      margin-bottom: 20px;
      background: linear-gradient(270deg, #EB3349 0%, #F45C43 100%);
      box-shadow: 0 2px 12px rgba(239, 69, 71, 0.4);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
    .close{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 10px;
      top: 10px;
      background-color: rgba(0, 0, 0, 0.03);
      width: 30px;
      height: 30px;
      border-radius: 50px;

      img{
        width: 10px;
      }
    }
  }
}