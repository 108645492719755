.buttonBlock {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 300px;
    padding: 10px 15px;
    font: inherit;
    font-size: 14px;
    font-weight: 500;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
    cursor: pointer;

    svg {
        flex-shrink: 0;
        margin-bottom: 2px;
        width: 16px;
        height: 16px;
    }

    span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
    }
}

.clearButton {
    svg {
        width: 16px;
        height: 16px;
    }
}

.attachedFileBlock {
    margin-top: 32px;
}

.attachedFileTile {
    margin-bottom: 20px;
    font-weight: 600;
}
