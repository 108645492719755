@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy/Gilroy-Light.ttf) format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy/Gilroy-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy/Gilroy-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy/Gilroy-SemiBold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy/Gilroy-Bold.ttf) format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Gilroy";
    src: url(../fonts/Gilroy/Gilroy-Black.ttf) format("truetype");
    font-weight: 800;
    font-style: normal;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    text-decoration: none;
    box-sizing: border-box;
    background: transparent;
}

body {
    font-size: 16px;
    font-family: "Gilroy", sans-serif;
    background-color: #f4f7f9;
}

::-moz-selection {
    color: #fff;
    background: #5111b6;
    text-shadow: none;
}

p {
    white-space: pre-line;
}
::selection {
    color: #fff;
    background: #5111b6;
    text-shadow: none;
}

h3 {
    color: #122443;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.5px;
    margin-bottom: 1em;
}

button {
    cursor: pointer;
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

$gradient-red: linear-gradient(270deg, #eb3349 0%, #f45c43 100%);
$gradient-blue: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);
$gradient-purple: linear-gradient(90deg, #4e54c8 0%, #8f94fb 100%);
$gradient-green: linear-gradient(90deg, #11998e 0%, #38ef7d 100%);
$gradient-yellow: linear-gradient(90deg, #ff8008 0%, #ffc837 100%);
$gradient-orange: linear-gradient(315deg, #ffe53b 0%, #ff2525 100%);
$gradient-violet: linear-gradient(270deg, #d988b9 0%, #713abe 100%);

.main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes spinner-border {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.reactIcon {
    font-size: 12px;
    color: #122443;
}

.loader {
    opacity: 0.5;
    width: 2rem;
    height: 2rem;
    margin: 2rem auto;
    border-radius: 50%;
    vertical-align: text-bottom;
    border: 0.25em solid #0072ff;
    border-right-color: transparent;
    animation: 0.75s linear infinite spinner-border;
}

.main-btn {
    width: 100%;
    color: #fff;
    font-size: 16px;
    padding: 21px 0;
    letter-spacing: 0.32px;
    text-align: center;

    border-radius: 10px;
    box-shadow: 0 2px 12px rgba(0, 110, 255, 0.4);
    background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

    &_green {
        background: $gradient-green;
    }

    &_orange {
        background: $gradient-orange;
    }

    &_yellow {
        background: $gradient-yellow;
    }

    &_purple {
        background: $gradient-purple;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.icon-btn {
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 7px;
    background-color: #fff;
    box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

    svg {
        width: 100%;
        height: 100%;
        display: block;
    }

    &::after {
        display: block;
        content: "";
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &_back {
        &::after {
            background-image: url(../img/ic-arrow-back.svg);
        }
    }

    &_close {
        &::after {
            background-image: url(../img/ic-close.svg);
        }
    }

    &_add {
        &::after {
            background-image: url(../img/ic-add.svg);
        }
    }

    &_settings {
        padding: 6px;
        &::after {
            background-image: url(../img/ic-settings.svg);
        }
    }

    &_filter {
        width: auto;
        display: flex;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.2);
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        &_active {
            background-color: white;
            &::after {
                filter: invert(0) !important;
            }
            .dot {
                width: 12px;
                height: 12px;
                background-color: #ff5a52;
                position: absolute;
                border-radius: 50%;
                top: -4px;
                right: -4px;
            }
        }
        &_pupil {
            &::after {
                filter: invert(1);
            }
        }
        &::after {
            min-width: 12px;
            background-image: url(../img/ic-filter-black.svg);
        }

        span {
            order: 1;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            color: #122443;
            margin-left: 0.5rem;
            letter-spacing: 0.2625px;
        }
    }
}

.range {
    width: 100%;
    height: 5px;
    display: flex;
    overflow: hidden;
    border-radius: 2.5px;
    background: #4ca2ff;
    margin-bottom: 0.5rem;

    &__norm {
        height: 100%;
        background: #52c22b;
    }

    &__not-norm {
        height: 100%;
        background: #4ca2ff;
    }
}

.progress-bar {
    width: 100%;
    height: 4px;
    position: relative;
    background: #e6eaee;
    border-radius: 2px;
    margin-bottom: 2rem;

    & > span {
        display: block;
        height: 100%;
        border-radius: 2px;
        background-image: $gradient-blue;
        position: relative;
        overflow: hidden;
    }

    &_red > span {
        background-image: $gradient-red;
    }
}

@keyframes inputAnimation {
    from {
        background-size: 0 1px;
    }
    to {
        background-size: 100% 1px;
    }
}

.swipe {
    width: 37px;
    padding: 1px;
    display: flex;
    cursor: pointer;
    border-radius: 11px;
    justify-content: flex-start;
    background: rgba(18, 36, 67, 0.06);

    &::before {
        content: "";
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50%;
        border: 7px solid #fff;
    }

    &_active {
        justify-content: flex-end;
        background: linear-gradient(270deg, #eb3349 0%, #f45c43 100%);
    }
}
.input-field__dropdown {
    position: absolute;
    background-color: white;
    width: 100%;
    top: 58px;
    max-height: 100px;
    overflow: scroll;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;

    p {
        text-align: left;
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    p:last-child {
        border: 0;
    }
    p:hover {
        text-decoration: underline;
    }
}
.input-field {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    textarea:placeholder-shown {
        height: 50px;
    }
    textarea {
        font-family: "Gilroy", sans-serif;
        resize: none;
        height: min-content;
    }
    textarea:focus {
        height: 100px;
    }
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="date"],
    input[type="datetime-local"] {
        order: 1;
        width: 100%;
        padding: 12px 0 10px;
        color: #122443;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: 0.3px;
        background-image: linear-gradient(to left, rgba(18, 36, 67, 0.1), rgba(18, 36, 67, 0.1));
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 100% 1px;
        margin-bottom: 20px;
    }
    textarea::placeholder,
    input[type="text"]::placeholder,
    input[type="number"]::placeholder,
    input[type="password"]::placeholder {
        opacity: 1;
        transition: all 0.3s ease-in-out;
        color: rgba(18, 36, 67, 0.4);
    }
    textarea + label,
    input[type="text"] + label,
    input[type="number"] + label,
    input[type="password"] + label,
    input[type="date"] + label,
    input[type="datetime-local"] + label {
        opacity: 0;
        text-transform: uppercase;
        color: #122443;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
        text-align: left;
        transform: translateY(20px);
    }
    textarea:focus,
    input[type="text"]:focus,
    input[type="number"]:focus,
    input[type="password"]:focus,
    input[type="date"]:focus,
    input[type="datetime-local"]:focus {
        animation-name: inputAnimation;
        animation-duration: 0.5s;
        animation-timing-function: ease-in-out;
        background-image: $gradient-blue;
    }
    &_green {
        textarea:focus,
        input[type="text"]:focus,
        input[type="number"]:focus,
        input[type="password"]:focus,
        input[type="date"]:focus,
        input[type="datetime-local"]:focus {
            background-image: $gradient-green;
        }
    }

    &_orange {
        textarea:focus,
        input[type="text"]:focus,
        input[type="number"]:focus,
        input[type="password"]:focus,
        input[type="date"]:focus,
        input[type="datetime-local"]:focus {
            background-image: $gradient-orange;
        }
    }

    &_yellow {
        textarea:focus,
        input[type="text"]:focus,
        input[type="number"]:focus,
        input[type="password"]:focus,
        input[type="date"]:focus,
        input[type="datetime-local"]:focus {
            background-image: $gradient-yellow;
        }
    }

    &_purple {
        input[type="text"]:focus,
        input[type="number"]:focus,
        input[type="password"]:focus,
        input[type="date"]:focus,
        input[type="datetime-local"]:focus {
            background-image: $gradient-purple;
        }
    }

    &_error {
        textarea:focus,
        input[type="text"]:focus,
        input[type="number"]:focus,
        input[type="password"]:focus,
        input[type="date"]:focus,
        input[type="datetime-local"]:focus {
            background-image: $gradient-red;
        }
    }

    &.error {
        input[type="text"],
        input[type="number"],
        input[type="password"],
        input[type="date"] {
            color: #eb3349;
            background-image: $gradient-red;

            &::placeholder {
                color: #eb3349;
                opacity: 0.8;
            }
        }
    }
    textarea:focus::placeholder,
    input[type="text"]:focus::placeholder,
    input[type="number"]:focus::placeholder,
    input[type="password"]:focus::placeholder {
        color: transparent;
    }
    textarea:focus + label,
    input[type="text"]:focus + label,
    input[type="password"]:focus + label,
    input[type="date"]:focus + label,
    input[type="datetime-local"]:focus + label {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.3s ease-in-out;
    }
    textarea:not(:placeholder-shown) + label,
    input[type="text"]:not(:placeholder-shown) + label,
    input[type="number"]:not(:placeholder-shown) + label,
    input[type="password"]:not(:placeholder-shown) + label {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.3s ease-in-out;
    }

    input[type="radio"] {
        width: 0;
        height: 0;
        opacity: 0;
    }

    input[type="radio"] + label {
        height: 40px;
        padding: 0 9px;
        font-size: 16px;
        font-weight: 500;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0.3px;
        color: rgba(18, 36, 67, 0.4);
        border-radius: 7px;
    }

    input[type="radio"]:checked + label {
        color: #fff;
        box-shadow: 0 2px 12px 0 rgba(0, 110, 255, 0.4);
        background: linear-gradient(to left, #00c6ff, #0072ff);
    }

    input[type="checkbox"] {
        width: 0;
        height: 0;
        opacity: 0;
    }

    input[type="checkbox"] + label {
        width: 100%;
        padding: 1rem;
        text-align: left;
        position: relative;
        margin-bottom: 10px;

        color: #122443;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: 0.23px;

        border-radius: 8px;
        background-color: #ffffff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        &::after {
            top: 1rem;
            right: 1rem;
            position: absolute;
            display: block;
            content: "";
            width: 37px;
            height: 22px;
            border-radius: 11px;
            background-color: rgba(18, 36, 67, 0.1);
        }

        &::before {
            top: 18px;
            right: 34px;
            z-index: 1;
            position: absolute;
            display: block;
            content: "";
            width: 18px;
            height: 18px;
            border-radius: 100%;
            background-color: #ffffff;
            transition: right 0.2s ease-in-out;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        }
    }

    input[type="checkbox"]:checked + label {
        &::after {
            background-image: linear-gradient(to left, #0072ff, #00c6ff);
        }

        &::before {
            right: 18px;
        }
    }

    &__focused-label {
        text-transform: uppercase;
        color: #122443;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .rdtSwitch,
    .rdtTimeToggle {
        font-weight: bold;
    }

    .rdtTimeToggle {
        padding: 15px 0;
    }

    .rdtSwitch:hover {
        background: #eeeeee;
        cursor: pointer;
    }

    &__phone-confirm {
        &-succes {
            position: absolute;
            top: 10px;
            right: 0;
            display: flex;
            align-items: center;
            color: #27ae60;

            img {
                margin-left: 2px;
            }
        }

        &-button {
            position: absolute;
            top: 4px;
            right: 0;
            color: #2b91ff;
            padding: 9px 10px;
            text-align: center;
            font-family: inherit;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0.3px;
            border-radius: 7px;
            background: rgba(71, 160, 255, 0.12);
            transition: opacity 0.3s;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}

.deleteStudent {
    width: 48px;
    height: 48px;
    position: absolute;
    border-radius: 50%;
    background-color: #fe2b2b;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -68px;
    top: 15px;
}

.list {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    list-style: none;
    margin-bottom: 1.5rem;

    &__item-wrap + &__item-wrap {
        margin-top: 1rem;
    }

    &__item-date {
        margin-bottom: 2px;
        padding-left: 12px;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.3px;
        color: rgba(18, 36, 67, 0.6);
    }

    &__item {
        width: 100%;
        padding: 1rem 3rem 1rem 1rem;
        display: block;
        position: relative;

        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: 0.3px;
        color: #122443;

        border-radius: 16px;
        background-color: #fff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        & + & {
            margin-top: 13px;
        }

        &_red {
            color: #fff;
            background: $gradient-red;

            .list__desc {
                color: #fff;
            }
        }
        &-count {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.26249998807907104px;
            text-align: left;
            color: #122443;
            opacity: 0.4;
            &-margin {
                margin-top: 10px;
            }
        }

        &-meeting,
        &-advice {
            padding: 1rem;

            &-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 20px;

                &-name {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            &-tag {
                display: inline-block;
                padding: 0 0.5rem;
                color: #fff;
                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0.2px;
                border-radius: 5px;
                background: linear-gradient(270deg, #eb3349 0%, #f45c43 100%);
            }

            &-status {
                flex-shrink: 0;
                font-size: 14px;
                opacity: 0.7;
            }

            &-read {
                color: green;
            }

            &-not-read {
                flex-shrink: 0;
                color: red;
            }

            &-canceled {
                flex-shrink: 0;
                color: #af7d00;
            }

            &-completed {
                flex-shrink: 0;
                color: #0057a8;
            }

            &-bottom-block {
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 20px;

                p {
                    opacity: 0.4;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: normal;
                    color: #122443;
                }
            }

            &-btn {
                padding: 7px 10px;
                font-family: inherit;
                color: #d20a00;
                border-radius: 7px;
                background-color: rgba(255, 123, 116, 0.2);

                &.accept {
                    color: green;
                    background-color: rgba(125, 255, 100, 0.2);
                }
            }

            &-desc {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &-button {
            font-family: inherit;
            text-align: left;
        }
    }

    &__desc {
        opacity: 0.4;
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
        color: #122443;
        margin-top: 0.5rem;
    }

    &__checkbox {
        display: flex;

        & + & {
            border-top: 1px solid rgba(18, 36, 67, 0.1);
        }

        input[type="checkbox"] {
            width: 0;
            height: 0;
            opacity: 0;
        }

        input[type="checkbox"] + label {
            width: 100%;
            display: block;
            cursor: pointer;
            padding: 1rem 0;
            position: relative;

            color: #122443;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.3px;

            &::after {
                top: 50%;
                right: 0;
                position: absolute;
                display: block;
                content: "";
                width: 22px;
                height: 22px;
                border-radius: 4px;
                transform: translateY(-50%);
                background: rgba(18, 36, 67, 0.06);
            }
        }

        input[type="checkbox"]:checked + label {
            &::after {
                background: #fff;
                box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
            }

            &::before {
                top: 50%;
                right: 0;
                z-index: 1;
                position: absolute;
                display: block;
                content: "";
                width: 22px;
                height: 22px;
                transform: translateY(-50%);
                background: url(../img/ic-check.svg) no-repeat center;
            }
        }
    }

    &__info-btn {
        top: 0;
        right: 0;
        width: 54px;
        height: 54px;
        display: block;
        cursor: pointer;
        position: absolute;
        background: url(../img/ic-info-black.svg) no-repeat center;
    }
}
.gesturedZone {
    overflow: hidden;
}

.page {
    width: 100%;
    max-width: 640px;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 5rem 1.5rem calc(84px + env(safe-area-inset-bottom, 20px));

    &.full-header {
        padding-top: 1.5rem;
    }

    &__small {
        padding-bottom: 60px !important;
    }
    &__header {
        top: 1.5rem;
        left: 1.5rem;
        right: 1.5rem;
        min-height: 2rem;
        position: absolute;

        &.full-header {
            position: relative;
            inset: 0;

            p {
                padding: 0 40px 24px;
            }
        }
    }

    &__content {
        flex-grow: 1;
        width: 100%;
    }

    &__desc {
        opacity: 0.4;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2625px;
        color: #122443;
        margin-top: -1rem;
        margin-bottom: 1rem;
    }

    &__icon-btn {
        position: absolute;
        top: 0;

        &_left {
            left: 0;
        }

        &_right {
            right: 0;
        }
    }

    &__title {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: 0.32px;
        color: #122443;
    }

    &__statistic-filters {
        margin-bottom: 60px;
    }
}

.walkthrough {
    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__score {
        position: absolute;
        top: 0;
        right: 0;
        width: 76px;
        height: 32px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        font-size: 14px;
        color: #122443;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: 0.23px;

        border-radius: 7px;
        background-color: #fff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        p:last-child {
            z-index: 1;
            color: #fff;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: -2px;
            width: 44px;
            height: 32px;
            transform: skewX(-10deg);
            background-image: linear-gradient(to left, #00c6ff, #0072ff);
        }
    }

    &__title {
        margin: 1.5rem 0;
        color: #122443;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.5px;

        & u {
            text-decoration: underline;
        }
    }

    &__image {
        margin-bottom: 2rem;
        text-align: center;

        img {
            max-width: 100%;
            object-fit: cover;
        }
    }
}

.answers-wrapper {
    width: 100%;
    height: 100%;
    margin-top: auto;
    text-align: center;

    &__prompt {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: 0.26px;
        color: rgba(18, 36, 67, 0.4);
    }

    &__btns-block {
        position: fixed;
        bottom: 84px;
        margin: 0 auto;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }

    &__btn {
        width: 64px;
        height: 64px;
        border-radius: 100%;
        background-color: #e6eaee;

        &::after {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background: url(../img/ic-next-no-active.svg) no-repeat center;
        }

        &_active {
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.04);
            background: linear-gradient(to left, #00c6ff, #0072ff);

            &::after {
                background: url(../img/ic-arrow-next.svg) no-repeat center;
            }
        }

        &_back {
            transform: rotate(-180deg);
        }
    }
}

@keyframes progressAnimation {
    from {
        color: #122443;
        background-size: 1% auto;
    }
    to {
        color: #fff;
        background-size: 100% auto;
    }
}

.answers {
    width: 100%;
    display: flex;
    flex-flow: column;
    overflow-y: auto;
    padding-top: 1rem;
    padding-bottom: calc(1.5rem + 64px);

    &__item {
        width: 100%;
        display: flex;

        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: 0.32px;

        &-image {
            width: 100%;
            padding: 1rem;
            border-radius: 7px;
            background-color: #fff;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

            img {
                max-height: 150px;
                object-fit: cover;
            }
        }

        input {
            width: 0;
            height: 0;
            opacity: 0;
            z-index: -1;
        }

        input + span {
            width: 100%;
            display: block;
            padding: 1rem;
            border-radius: 7px;
            color: #122443;
            background-color: #fff;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        }

        input:checked + span,
        input:checked + &-image {
            color: #fff;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
            animation-name: progressAnimation;
            animation-timing-function: ease-in-out;
            animation-duration: 0.5s;
            background-position: left;
            background-repeat: no-repeat;
            background-image: linear-gradient(to left, #00c6ff, #0072ff);
        }

        & + & {
            margin-top: 13px;
        }
    }

    &__item-free {
        width: 100%;
        display: flex;
        padding: 10px;
        border-radius: 7px;
        background-color: #fff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        input {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.38;
            letter-spacing: 0.32px;
            width: 100%;
            display: block;
            padding: 0.5rem 1rem;
            border: 1px solid rgba(18, 36, 67, 0.2);
            border-radius: 7px;
            color: #122443;

            &::placeholder {
                font-style: italic;
                opacity: 0.7;
            }
        }
    }
}

.auth-page {
    &__wrapper {
        width: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.2px;
        text-align: center;
        color: rgba(17, 36, 68, 0.4);

        a {
            color: #122443;
        }
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__register {
        color: #122443;

        &-header {
            text-align: center;
        }

        &-text {
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.262px;
        }
    }

    &__info {
        border-radius: 7px;
        background-color: #ffffff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        margin-bottom: 20px;
        color: #122443;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        text-align: initial;
        letter-spacing: 0.4px;

        &-item {
            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 20px;

            & + & {
                border-top: solid 0.5px rgba(18, 36, 67, 0.1);
            }
        }

        &-title {
            opacity: 0.4;
        }

        &-desc {
            opacity: 0.4;
            font-size: 14px;
            line-height: 1.57;
            letter-spacing: 0.26px;
        }
    }

    &__header-btn {
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0.23px;
        color: rgba(18, 36, 67, 0.4);
    }

    &__title {
        color: #122443;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.5px;
    }

    &__input-group {
        width: 100%;
        margin-bottom: 2rem;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: 0.23px;
        text-align: center;
        color: #122443;

        &_row {
            display: flex;
            padding: 3px;
            border-radius: 7px;
            background-color: #e8ecef;
            margin-bottom: 2rem;
        }

        a {
            color: #122443;
        }
    }

    &__btn {
        margin-top: auto;
    }

    &__vk-block {
        margin-bottom: 2rem;

        p {
            margin-top: 1.25rem;
            font-size: 14px;
            text-align: left;
        }
    }

    &__buttons {
        display: grid;
        justify-content: center;
        grid-auto-columns: max-content;
        gap: 20px;
    }

    &__register-buttons {
        margin: -24px 0 20px;
    }

    &__dnevnikru {
        span {
            display: inline-block;
            padding-top: 5px;
            color: #1172b2;
            font-size: 18px;
        }
    }
}
.addPupils-page {
    background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

    &__title {
        color: #ffffff;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 2rem;

        color: #fff;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.4px;
    }

    &__footer {
        position: relative;
        color: #fff;
        padding: 1rem;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.2);

        &-title {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.4px;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 65px;
        }

        &-desc {
            opacity: 0.8;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.3px;
        }
    }

    &__copy-btn {
        position: absolute;
        top: 11px;
        right: 1rem;
        width: 54px;
        height: 54px;
        padding: 1rem;
        background: #fff;
        border-radius: 100%;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        &::after {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            background: url(../img/ic-copy.svg) no-repeat center;
        }
    }

    &__btn-wrapper {
        padding: 1rem 2rem;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.2);
        cursor: pointer;

        a,
        button,
        span {
            font-family: inherit;
            font-size: 16px;
            color: #fff;
        }
        span{
            margin: 0;
        }

        p {
            font-size: 12px;
        }

        &_orange{
            background: #faa229;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        width: 280px;
    }


    &__add-list {
        margin: 0.5rem 0;
        color: #fff;
    }
}

.code-page {
    background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

    &__title {
        color: #ffffff;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        color: #fff;
        font-size: 20px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.4px;
    }

    &__footer {
        position: relative;
        color: #fff;
        padding: 1rem;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.2);

        &-title {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.4px;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-right: 65px;
        }

        &-desc {
            opacity: 0.8;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.3px;
        }
    }

    &__copy-btn {
        position: absolute;
        top: 11px;
        right: 1rem;
        width: 54px;
        height: 54px;
        padding: 1rem;
        background: #fff;
        border-radius: 100%;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        &::after {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            background: url(../img/ic-copy.svg) no-repeat center;
        }
    }

    &__btn-wrapper {
        padding: 1rem;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.2);
        cursor: pointer;

        a,
        button,
        span {
            font-family: inherit;
            font-size: 14px;
            color: #fff;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        width: 280px;
    }


    &__info{
        background-color: white;
        max-width: 440px;
        margin-top: 2rem;
        border-radius: 7px;
        padding: 1rem;
        margin-bottom: 2rem;
    }
    &__info-text{
        font-size: 14px;
        color: #212121;
    }

    &__add-list {
        margin: 2rem 0;
        color: #fff;
    }
}

.code {
    overflow: hidden;
    width: fit-content;
    border-radius: 20px;
    background: #fff;
    margin-top: 2rem;
}

@keyframes tools {
    from {
        opacity: 0;
        transform: translateY(20%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.tools {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1.5rem 1rem;
    background: rgba(0, 0, 0, 0.5);

    &__pupil {
        justify-content: flex-start;
        padding-top: 65px;
    }

    &__items {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-flow: column;
        margin-bottom: 1.5rem;
        border-radius: 20px;
        background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

        animation-name: tools;
        animation-duration: 0.4s;

        &-pupil {
            margin: 1.5rem 0 0;
        }
    }

    &__item {
        padding: 1rem;
        border-radius: 7px;
        background: rgba(255, 255, 255, 0.16);
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        color: #fff;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.32px;

        & + & {
            margin-top: 0.5rem;
        }
    }

    &__close-btn {
        width: 58px;
        height: 58px;
        background: #fff;
        border-radius: 100%;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: url(../img/ic-close.svg) no-repeat center / 12px;
        }
    }

    &__open-btn {
        z-index: 1;
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0.5rem;
        width: 44px;
        height: 44px;
        border-radius: 100%;
        box-shadow: 0 2px 12px rgba(0, 110, 255, 0.4);
        background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: url(../img/ic-add-white.svg) no-repeat center / 12px;
        }
    }

    &__pupil_open-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 20px;
        font-family: inherit;
        color: #122443;
        height: 40px;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
    }
}

.bool-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    // bottom: calc(3rem + 58px);
    bottom: 160px;

    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #122443;
}

.send-btn {
    bottom: 84px;
    left: 1.5rem;
    right: 1.5rem;
    position: absolute;

    button {
        background-image: $gradient-red;
    }
}

.send-btn-static {
    position: static;
}

.select-all {
    width: 100%;
    position: relative;
    color: #122443;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.4px;
    margin-bottom: 0.5rem;

    &::after {
        top: 50%;
        right: 0;
        position: absolute;
        display: block;
        content: "";
        width: 22px;
        height: 22px;
        border-radius: 4px;
        transform: translateY(-50%);
        background: rgba(18, 36, 67, 0.06);
    }

    &_active {
        &::after {
            background: #fff;
            box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        }

        &::before {
            top: 50%;
            right: 0;
            z-index: 1;
            position: absolute;
            display: block;
            content: "";
            width: 22px;
            height: 22px;
            transform: translateY(-50%);
            background: url(../img/ic-check.svg) no-repeat center;
        }
    }
}

.walk-range-wrapper {
    flex-grow: 1;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    p {
        align-self: center;
        max-width: 247px;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.2625px;
        color: rgba(18, 36, 67, 0.4);
    }

    &__btns-block {
        position: relative;
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }

    &__button {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        margin-top: 2rem;
        position: relative;
        margin-bottom: 1.5rem;
        background-color: rgba(71, 160, 255, 0.12);
        background-image: url(../img/ic-hend-tap.svg);
        background-position: center;
        background-repeat: no-repeat;

        &::after {
            content: "";
            top: -0.5rem;
            width: 206px;
            height: 42px;
            display: block;
            position: absolute;
            left: calc((100% - 206px) / 2);
            background: url(../img/ic-slider-arrows.svg) no-repeat center/contain;
        }

        &_active {
            cursor: pointer;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.041002);
            background-image: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                position: initial;
                background: url(../img/ic-arrow-next.svg) no-repeat center;
            }
        }
    }
}

.walk-range {
    width: 100%;
    height: 2rem;
    position: relative;

    &__min,
    &__max {
        position: absolute;
        top: 100%;
        opacity: 0.4;
        color: #122443;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.2625px;
    }

    &__min {
        left: 0;
    }

    &__max {
        right: 0;
    }
    &__clickHandler {
        width: 50px;
        height: 50px;
        margin-left: -25px;
        margin-top: -25px;
    }
    &__slider {
        width: 100%;
        height: 3px;
        bottom: calc((100% - 3px) / 2);
        border-radius: 2px;
        position: absolute;
        background: #deebf9;
        -webkit-appearance: none;
        appearance: none;
        &::-moz-range-track {
            position: relative;
            z-index: -1;
        }
        &::-moz-range-progress {
            background-color: #0072ff;
        }
        &::-webkit-slider-thumb {
            position: relative;
            -webkit-appearance: none;
            appearance: none;

            z-index: 2;
            width: 2rem;
            height: 2rem;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.041002);
            background: url(../img/ic-slider-thumb.svg) no-repeat center / contain;
        }
        &::-moz-range-thumb {
            position: absolute;

            -webkit-appearance: none;
            appearance: none;

            z-index: 100;
            width: 2rem;
            height: 2rem;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.041002);
            background: url(../img/ic-slider-thumb.svg) no-repeat center / contain;
        }
        &::-ms-thumb {
            position: relative;
            -webkit-appearance: none;
            appearance: none;

            z-index: 2;
            width: 2rem;
            height: 2rem;
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.041002);
            background: url(../img/ic-slider-thumb.svg) no-repeat center / contain;
        }
    }

    &__progress {
        left: 0;
        height: 3px;
        border-radius: 2px;
        bottom: calc((100% - 3px) / 2);
        background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);
        position: absolute;
    }

    &__selector {
        z-index: 2;
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        border-radius: 50%;
        position: absolute;
        transform: translateX(-50%);
        bottom: calc((100% - 2rem) / 2);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.041002);
        background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);
    }

    &__dotes {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: flex;
        list-style: none;
        position: absolute;
        align-items: center;
        justify-content: space-between;

        li {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            border: 1px solid #f4f7f9;
            background: linear-gradient(270deg, #00c6ff 0%, #0072ff 100%);
        }
    }
}

.search {
    width: 100%;
    height: 36px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: rgba(118, 118, 128, 0.12);

    input {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: rgb(18, 36, 67);

        &::placeholder {
            font-family: "Gilroy", sans-serif;
        }
    }
}
.modal-messages {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(19, 37, 68, 0.4);
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;

    .page {
        background-color: white;
        min-height: 0;
        padding: 25px 34px;
        border-radius: 16px;

        h3 {
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            letter-spacing: 0.5px;
            text-align: left;
            margin-bottom: 13px;
        }
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.26249998807907104px;
            text-align: left;
            opacity: 0.8;
            margin-bottom: 18px;
        }
        button,
        a {
            background-color: rgba(71, 160, 255, 0.2);
            border-radius: 7px;
            height: 44px;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.4000000059604645px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(71, 160, 255, 1);
            width: calc(100% - 74px);
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.modal-messages {
    &__buttons {
        display: flex;
        justify-content: center;
        column-gap: 8px;

        @media (max-width: 499px) {
            flex-direction: column;
            row-gap: 8px;
        }
    }
}

.modal-messages.tg-connect {
    p {
        margin: 24px 0;
    }

    & .dont-show {
        button {
            background-color: transparent;
            padding: 0;
            height: auto;
            font-size: 14px;
            line-height: 130%;
        }
    }
}

.popup-tg {
    position: relative;

    &-close {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 3px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
            opacity: 0.8;
        }
    }
}

.cookies {
    position: fixed;
    bottom: 65px;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .page {
        background-color: rgba(255, 255, 255, 0.95);
        min-height: 0;
        padding: 16px;
        border-radius: 16px;
        border: 1px solid rgba(128, 128, 128, 0.1);
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);
        margin: 0 18px;
        max-width: 610px;

        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.26249998807907104px;
            text-align: left;
            opacity: 0.8;
            margin-bottom: 16px;
        }

        button {
            background-color: rgba(71, 160, 255, 0.2);
            border-radius: 7px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.4000000059604645px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #47a0ff;
            margin: 0 auto;
            padding: 4px 8px;
        }
    }
}

.textarea-autosize {
    overflow-y: hidden;
}

.region {
    &-content {
        padding: 0 20px;
    }

    &-find {
        margin-bottom: 20px;

        input {
            background: rgba(18, 36, 67, 0.05);
            border: 1px solid rgba(18, 36, 67, 0.2);
            border-radius: 6px;
            color: #122443;
            font-size: 16px;
            padding: 10px;
            width: 100%;
        }
    }

    &-select-btn {
        background-image: linear-gradient(270deg, rgba(18, 36, 67, 0.1), rgba(18, 36, 67, 0.1));
        background-position: 0 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        color: #122443;
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 1.38;
        padding: 12px 10px 10px;
        resize: none;
        text-align: left;
        width: 100%;
        transition: background-color 0.3s;

        @media (hover: hover) {
            &:hover {
                background-color: rgba(47, 141, 255, 0.5);
            }
        }
    }
}

.phone-verification {
    &__contacts-wrapper {
        min-height: 400px;
        text-align: center;
    }

    &__input-field {
        margin-top: 24px;

        input {
            width: 100px;
            color: #122443;
            font-family: inherit;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 6px;
            text-align: center;
            border-bottom: 2px solid #47a0ff;
        }
    }

    &__confirm-btn {
        margin-top: 100px;

        button {
            padding: 22px 114px;
            color: #47a0ff;
            text-align: center;
            font-family: inherit;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0.3px;
            border-radius: 7px;
            background: rgba(71, 160, 255, 0.12);
            transition: opacity 0.3s;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    &__timer {
        margin-top: 12px;
        color: #122443;
        font-family: inherit;
        font-size: 20px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 2px;
        text-align: center;
    }
}

.vk-button {
    padding: 10px 20px;
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    color: white;
    background-color: #5181b8;
    border-radius: 12px;

    @media (hover: hover) {
        transition: opacity 0.3s;

        &:hover,
        &:disabled {
            opacity: 0.8;
        }
    }
}

.navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(60px + env(safe-area-inset-bottom, 20px));
    background: #fff;

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        max-width: 640px;
        margin: auto;
        padding: 0 4.5rem env(safe-area-inset-bottom, 20px);
    }
}

.pwa-install {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &___inner {
        position: relative;
    }

    &__close {
        position: absolute;
        top: 8px;
        right: 8px;
        padding: 3px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
            color: #2330ba;
            opacity: 0.8;
        }
    }

    h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.26249998807907104px;
        opacity: 0.8;
    }

    &__content {
        background-color: #e7effe;
        color: #2330ba;
        min-height: 0;
        padding: 24px 20px 20px;
        border-radius: 16px;
        border: 1px solid rgba(35, 48, 186, 0.4);
        box-shadow: 0 2px 14px 2px rgba(38, 51, 73, 0.15);
        width: 300px;
        text-align: center;
    }

    &__buttons {
        display: flex;
        align-items: center;
        flex-flow: column;
        gap: 10px;
        margin-top: 16px;

        button {
            width: 200px;
            background-color: rgba(71, 160, 255, 0.1);
            border-radius: 7px;
            color: rgba(35, 48, 186, 0.8);
            padding: 10px 15px;
            font-size: 14px;
            font-weight: 500;
            line-height: 100%;
            text-align: center;
            border: 1px solid rgba(35, 48, 186, 0.4);

            &.install {
                background-color: rgba(254, 204, 98, 0.8);
            }
        }
    }

    &__instruction {
        margin-top: 20px;

        &-desc {
            margin: 12px auto 0;
            line-height: 1.7;
            text-align: center;

            b {
                font-weight: 600;
            }

            svg {
                margin-bottom: 2px;
                width: 20px;
                height: 20px;
                color: #0079d0;
            }
        }
    }
}

.data-error {
    font-weight: 500;
    color: #ff5d5d;
    text-align: center;
}
