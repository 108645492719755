.dateTitle {
    margin: 16px 0 10px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.list > div {
    margin-top: 12px;
}

.noMeetings {
    margin-top: 24px;
    text-align: center;
}

.createMeetingBtn {
    position: sticky;
    top: 20px;
    z-index: 1;
    margin-bottom: 32px;
}
