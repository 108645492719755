.problems-list{
  display: flex;
  flex-direction: column;

  &__item{
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    border-bottom: 1px solid #1224431A;
  }
  &__item:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &__item:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-color: transparent;
  }

  &__row{
    display: flex;
    align-items: center;
  }
  &__text{
    color: black;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.26249998807907104px;
    text-align: left;
    margin-right: 12px;

    &-span {
      font-style: italic;
      color: gray;
    }
  }
  &__img{
    margin-right: 12px;
  }
  &__btn{
    box-shadow: 0 2px 10px -3px #2633491A;
    background-color: #F4F7F9;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn{
    img{
      width: 4px;
      height: 8px;
    }
  }
}