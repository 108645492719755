.suggestions-page__contacts{
    display: flex;
    width: 100%;
    align-items: center;
}

.suggestions-page__contacts > img{
    width: 20px;
}

.suggestions-page__contacts__phones{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    row-gap: 5px;
}

.suggestions-page__contacts__phones a{
    font-size: 14px;
    font-weight: 500;
    color: #636C76;
}

.suggestions-page__contacts__buttons{
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.suggestions-page__contacts__btn{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.3s;
    background-color: white;

}

.suggestions-page__contacts__btn > img {
    width: 19px;
}

.suggestions-page__contacts__btn:nth-child(2) > img {
    margin-left: -2px;
}