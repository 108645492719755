.tests-page {
    position: relative;
    padding-bottom: 0;
    background-image: $gradient-red;

    &__main {
        margin: -5rem -24px 0;
        padding: 5rem 1.5rem 2.5rem;
    }

    &__main-title {
        color: #fff;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.4px;
        margin-bottom: 0.5rem;
    }

    &__title {
        color: #ffffff;
    }

    &__passed {
        flex-grow: 1;
        padding: 1.5rem;
        background: #f4f7f9;
        margin: -1rem -1.5rem 0;
        border-radius: 20px 20px 0px 0px;

        animation-name: selectionUp;
        animation-duration: 0.6s;
        animation-timing-function: ease-out;
    }

    &__passed-title {
        color: #122443;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.4px;
        margin-bottom: 12px;
    }

    &__date {
        margin-top: 0;
        margin-bottom: .5rem;
    }

    &__passed-test {
        & + & {
            margin-top: 1rem;
        }
    }
}
.tests-list {
    &__item {
        width: 100%;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 15px;

        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: 0.3px;
        color: #122443;

        border-radius: 16px;
        background-color: #fff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        & > div:first-child {
            flex: 1 1 auto;
        }

        & + & {
            margin-top: 13px;
        }

        &_red {
            color: #fff;
            background: $gradient-red;

            .list__desc {
                color: #fff;
            }
        }
        &-count{
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.26249998807907104px;
            text-align: left;
            color: #122443;
            opacity: 0.4;
            &-margin{
                margin-top: 10px;
            }
        }
    }

    &__info-btn {
        width: 45px;
        height: 45px;
        display: block;
        cursor: pointer;
        background: url(../img/ic-info-black.svg) no-repeat center;
        background-size: 100%;
        opacity: 0.6;
    }
}

.test {
    &__item {
        position: relative;
    }

    &__delete-btn {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        padding: 7px 10px;
        font-family: inherit;
        font-size: 14px;
        color: #d20a00;
        border-radius: 7px;
        background-color: rgba(255, 123, 116, 0.2);
    }
}